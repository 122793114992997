@import url('../../assets/styles/util.less');
@import url('./index.pc.less');

.pc( {
        .basic {
            display: flex;
            flex-direction: column;
            width: @formWidth;
            max-width: 100%;
            margin: 40px auto 0 auto;

            .label {
                color: #798488;
            }

            .btn-upload {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FAFAFA;
                box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.4);
                border-radius: 8px;
                width: 80px;
                height: 80px;
                overflow: hidden;
            }

            .input-item {
                margin-bottom: 22px;
                .suffix-wrapper {
                    width: 100%;
                    .ant-input {
                        width: 600px;
                    }
                }
            }
        }
    }

);
