@import url('../../assets/styles/util.less');
@import url('./index.pc.less');

.pc( {
        .rule {
            margin: 41px auto 0 auto;
            display: flex;
            flex-direction: column;
            width: @formWidth;
            max-width: 100%;

            .label {
                color: #798488;
            }

            .input-item {
                &.progress {
                    flex-basis: 75%;
                    .progress-wrapper {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .ant-slider {
                            flex-basis: 80%;
                        }

                        span {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #22304a;
                            margin-left: 17px;
                        }
                    }
                }

                &.votes {
                    // flex-basis: 25%;

                    .input-common {
                        height: 40px;
                        width: 125px;
                    }
                }

                // &.executor {
                //     .ant-input {
                //         width: 536px;
                //     }
                // }
            }

            .drag-area {
                height: 100px;
                width: 409px;
                border-radius: 8px;
                background: #FAFAFA;
                box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.4);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #C2C2C2;
            }

            .ant-checkbox {
                margin-top: 17px;
            }
        }
    }

);
