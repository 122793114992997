.dao-detail {
    .detail-header {
        width: 100%;
        height: 138px;
        background: #ffffff;
        box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
            -3px -3px 8px rgba(255, 255, 255, 0.8);
        padding: 23px 43px;
        z-index: 100;
        .title {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            letter-spacing: -0.288889px;
            color: #22304a;
            margin-bottom: 5px;
        }
        .text {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */

            color: #798488;
            margin-bottom: 8px;
        }
    }
    .detail-content {
        display: flex;
        .content-left {
            width: 237px;
            flex-shrink: 0;
            background: #ffffff;
            box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
                -3px -3px 8px rgba(255, 255, 255, 0.8);
            border-radius: 0px;
            min-height: 685px;
            margin-top: 3px;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px;
            text-align: center;
            padding-top: 37px;
            .logo {
                width: 80px;
                height: 80px;
                border: 0.5px solid #d8d8d8;
                box-sizing: border-box;
                border-radius: 50%;
                margin-bottom: 16px;
            }
            .title {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                /* identical to box height, or 162% */

                letter-spacing: -0.288889px;

                color: #22304a;
            }
            .symbol {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                /* identical to box height, or 162% */

                letter-spacing: -0.288889px;

                color: #22304a;
                margin-bottom: 16px;
            }
            .address {
                background: #fafafa;
                box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.4);
                border-radius: 8px;
                width: 165px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 13px;
                margin-bottom: 23px;
                > span {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 26px;
                    /* identical to box height, or 217% */

                    text-align: center;
                    letter-spacing: -0.288889px;

                    color: #22304a;
                    margin-right: 9px;
                }
            }
            .links {
                list-style: none;
                width: 100%;
                text-align: center;
                > li {
                    cursor: pointer;
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 26px;
                    /* identical to box height, or 186% */

                    letter-spacing: -0.288889px;

                    color: #798488;
                    margin-bottom: 16px;
                    cursor: pointer;
                    position: relative;
                }
                .active-link {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 26px;
                    /* identical to box height, or 186% */

                    letter-spacing: -0.288889px;

                    color: #22304A;
                    &::after {
                        content: '';
                        display: block;
                        width: 3px;
                        height: 28px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        background: #22304A;
                        border-radius: 5px;
                    }
                }
            }
        }
        .content-right {
            flex: 1;
            padding: 42px 126px 42px 105px;
            width: 100%;
            max-width: 1083px;
        }
    }
}
