.vote-details {
    background: #ffffff;
    box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.5);
    border-radius: 8px;
    padding: 40px 47px;
    .title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #22304a;
        margin-bottom: 21px;
    }
}
.vote-list {
    .vote-item {
        margin-bottom: 24px;
        .vote-data-container {
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;
            .vote-desc {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;

                color: #22304a;
                max-width: 507px;
                margin-bottom: 12px;
            }
            .vote-data {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                min-width: 80px;
                color: #22304a;
            }
        }
    }
    .btn-view-all {
        height: 48px;
        margin: 0 auto;
    }
}
