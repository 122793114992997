.container {
    padding: 32px 47px;
    padding-left: 105px;
    max-width: 1320px;
    .btn-back {
        background: #ffffff;
        border: 1px solid #22304A;
        box-shadow: inset 0px -1px 1px rgba(85, 102, 123, 0.4);
        border-radius: 8px;
        width: 120px;
        height: 48px;
        margin-bottom: 24px;
        color: inherit;
    }
    .title {
        margin-bottom: 24px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */

        /* Navy Blue */

        color: #22304a;
    }
    .form {
        background: #ffffff;
        box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.4);
        border-radius: 8px;
        width: 100%;
        padding: 52px 40px;
        border: 1px solid;

        border-image-source: linear-gradient(
            113.28deg,
            #ffffff 3.93%,
            #c6d7eb 96.73%,
            #759cca 96.73%
        );
    }
}
