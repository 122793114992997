.vote-container {
    background: #ffffff;
    box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.5);
    border-radius: 8px;
    padding: 21px 41px;
    margin-bottom: 24px;
    .title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        /* Navy Blue */

        color: #22304a;
        margin-bottom: 17px;
    }
    .your-vote {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 14px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        color: #767676;
    }
    .btn-vote {
        width: 100% !important;
        height: 40px !important;
        margin: 0 auto;
    }
}
