.undo-claim-container {
    background: #ffffff;
    box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.5);
    border-radius: 8px;
    border: 1px solid;
    padding: 23px 37px;
    margin-bottom: 17px;

    border-image-source: linear-gradient(
        113.28deg,
        #ffffff 3.93%,
        #c6d7eb 96.73%,
        #759cca 96.73%
    );
    .title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        color: #22304a;
        margin-bottom: 14px;
    }
    .list-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .label {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */

            color: #767676;
        }
        .value {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: right;

            color: #22304a;
        }
    }
    .btn-undo {
        background: #ffffff;
        border: 1px solid #22304A;
        box-sizing: border-box;
        box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
            -3px -3px 8px rgba(255, 255, 255, 0.8);
        border-radius: 8px;
        width: 100% !important;
        height: 48px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        color: #22304a;
        margin-top: 14px;
    }
}
