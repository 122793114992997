@import url('../../../../assets/styles/util.less');

.pc( {
        .members {
            h1 {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: #22304A;
            }

            p {
                margin-top: 16px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #22304A;
            }

            .ant-table {
                .ant-table-thead {
                    border-bottom: 1px solid rgba(105, 141, 173, 0.4);
                    border-radius: 8px;

                    .ant-table-cell {
                        background: transparent;
                    }
                }

                .ant-table-tbody {
                    .ant-table-row {
                        // border: 0.5px solid #D8D8D8;
                        // box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
                        // -3px -3px 8px rgba(255, 255, 255, 0.8);
                        // margin-top: 8px;

                        // td {
                        //     padding-bottom: 5em;
                        // }
                    }
                }
            }
        }
    }

);
