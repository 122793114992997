@import url('../../../../assets/styles/util.less');

.pc( {
        .configuration {
            .label {
                color: #798488;
            }
            .drag-area {
                width: 100%;
                border-radius: 8px;
                background: #FAFAFA;
                box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.4);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                resize: none;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: -0.288889px;
                color: #22304a;
            }

            .ant-btn.btn-update {
                height: 48px;
                width: 140px;
                border-radius: 8px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #22304a;
            }

            .input-item {
                &.progress {
                    flex-basis: 75%;

                    .progress-wrapper {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .ant-slider {
                            flex-basis: 80%;
                        }

                        span {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #22304a;
                            margin-left: 17px;
                        }
                    }
                }

                &.votes {
                    // flex-basis: 25%;

                    .input-common {
                        height: 40px;
                        width: 125px;
                    }
                }
            }
        }
    }

);
