@import url('../../assets/styles/util.less');

@formWidth: 600px;

.pc( {
        .building {
            display: flex;
            flex-direction: column;
            padding: 0 20px 40px;
            
            .btn-group {
                margin: 41px auto 0 auto;
                align-items: flex-end;
                min-width: @formWidth;
                flex: 1;

                .btn-common {
                    height: 48px;
                    width: 120px;
                    border-radius: 8px;
                }
            }
        }
    }

);
