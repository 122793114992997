.proposals-container {
    .header {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // flex-wrap: wrap;
        margin-bottom: 36px;
        width: 100%;
        .header-info {
            .title {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                /* identical to box height */

                color: #22304a;
            }
            .text {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height */

                color: #798488;
                // width: 620px;
            }
        }
    }
    .custom-tabs {
        width: 100%;
        margin-bottom: 12px;
    }
    .proposals-list {
        .proposals-item {
            background: #ffffff;
            border: 0.5px solid #d8d8d8;
            box-sizing: border-box;
            box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
                -3px -3px 8px rgba(255, 255, 255, 0.8);
            border-radius: 8px;
            padding: 25px 24px;
            cursor: pointer;
            width: 100%;
            height: 180px;
            .title {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                /* identical to box height, or 100% */

                /* Navy Blue */

                color: #22304a;
                margin-bottom: 7px;
            }
            .desc {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */
                color: #000000;
                text-overflow: ellipsis;
                overflow: hidden;
                // white-space: nowrap;
                height: 22px;
            }
            .footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .start-time {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;

                    color: #798488;
                }
            }
        }
    }
}
