@import url('../../../assets/styles/util.less');

.pc( {
        .deposit-assets {
            .ant-btn {
                margin: 30px auto 10px auto;
                height: 48px;
                width: 240px;
            }

            .input-item {

                .label {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    span {
                        &:last-child {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 21px;
                            color: #22304a;
                        }
                    }
                }

                .assets-selector {
                    position: relative;
                    width: 100%;

                    >img {
                        position: absolute;
                        left: 22px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 24px;
                        object-fit: contain;
                        z-index: 10;
                    }
                }

                .ant-select {
                    width: 100%;
                    .ant-select-arrow {
                        width: 24px;
                        height: 24px;
                        margin-top: -12px;
                        background: white;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
                        -3px -3px 8px rgba(255, 255, 255, 0.8);
                    }

                    .ant-select-selector {
                        height: 48px;
                        width: 100%;
                        background: #FAFAFA;
                        box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.4);
                        border-radius: 8px;
                    }

                    .ant-select-selection-item {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: #22304a;
                        display: flex;
                        align-items: center;
                        padding-left: 51px;
                    }
                }
            }
        }
    }

);
