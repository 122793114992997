@import url('../../../assets/styles/util.less');

.pc( {
        .wallet-status {
            .btn-common.btn-connect {}

            .btn-common.btn-connected {
                // background: #E7F5FF;
                border: 2px solid #FFFFFF;
                border-radius: 10px;
                box-shadow: inset -3px -3px 4px rgba(255, 255, 255, 0.88),
                inset 2px 2px 7px rgba(73, 103, 134, 0.36);
                filter: drop-shadow(-1px -1px 4px #FFFFFF) drop-shadow(0.5px 0.5px 0.5px rgba(0, 0, 0, 0.16));
            }
        }
    }

);
